<script setup lang="ts">
import type { Submenu, CtaMenu } from "~/models/Menu";
const { submenu, type } = defineProps<{
  submenu: Submenu[];
  type: string;
  cta?: CtaMenu[];
}>();

const activeSubmenu = ref("");

function handleMouseEnter(submenuValue: string) {
  activeSubmenu.value = submenuValue;
}

function handleMouseLeave() {
  activeSubmenu.value = "";
}
</script>
<template>
  <div class="submenu" @mouseleave="handleMouseLeave">
    <div class="submenu-lvl-1">
      <div class="submenu-lvl-1-container">
        <ul class="submenu-lvl-1-list">
          <li
            v-for="menu in submenu"
            :key="menu.title"
            class="submenu-lvl-1-list-item"
            :class="type"
            @mouseenter="menu.uid ? handleMouseEnter(menu.uid) : null"
          >
            <CustomNuxtLink
              v-if="menu.url"
              :to="menu.url"
              class="submenu-lvl-1-list-item-link"
            >
              <i :class="menu.icon"></i>
              <div class="submenu-lvl-1-list-item-text">
                <span class="submenu-lvl-1-list-item-text-title" v-html="menu.title" />
                <span
                  v-if="menu.subtitle"
                  class="submenu-lvl-1-list-item-text-desc"
                  v-html="menu.subtitle"
                />
              </div>
            </CustomNuxtLink>
            <template v-else>
              <i :class="menu.icon"></i>
              <div class="submenu-lvl-1-list-item-text">
                <span class="submenu-lvl-1-list-item-text-title" v-html="menu.title" />
                <span
                  v-if="menu.subtitle"
                  class="submenu-lvl-1-list-item-text-desc"
                  v-html="menu.subtitle"
                />
              </div>
            </template>
          </li>
        </ul>
      </div>
      <div
        v-for="menu in submenu"
        v-show="activeSubmenu === menu.uid"
        :key="menu.label"
        class="submenu-lvl-1-2"
        :data-submenu="menu.uid"
        @mouseenter="handleMouseEnter(menu.uid)"
      >
        <div
          v-for="bigmenu in menu.big_menu"
          :key="bigmenu.title"
          class="submenu-lvl-1-2-container"
        >
          <div class="submenu-lvl-1-2-title">{{ bigmenu.title }}</div>
          <ul class="submenu-lvl-1-2-list">
            <li
              v-for="item in bigmenu.items"
              :key="item.label"
              class="submenu-lvl-1-2-list-item"
            >
              <CustomNuxtLink
                :to="item.url"
                class="submenu-lvl-1-2-list-item-link"
                :external="item.external"
                >{{ item.label }}</CustomNuxtLink
              >
            </li>
          </ul>
        </div>
        <NuxtLink
          v-if="menu.show_all"
          :to="menu.show_all.url"
          class="submenu-lvl-1-2-show-more"
          >{{ menu.show_all.label }}</NuxtLink
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.submenu {
  padding: var(--showcase-size-225);
  max-width: max-content;
  position: absolute;
  top: 32px;
  left: -68px;
  display: none;
  &-lvl {
    &-1 {
      border-radius: 11px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      width: max-content;
      padding: var(--showcase-size-050);
      display: flex;
      &-2 {
        padding: var(--showcase-size-050) var(--showcase-size-150);
        margin-left: var(--showcase-size-050);
        border-left: 1px solid rgba(0, 0, 0, 0.19);
        display: flex;
        gap: 80px;
        &-show-more {
          position: absolute;
          bottom: var(--showcase-size-400);
          right: var(--showcase-size-400);
          color: var(--showcase-color-additional-dark);
        }
        &-title {
          font-weight: 500;
          margin-bottom: var(--showcase-size-150);
          color: #000;
          text-align: left;
        }
        &-list {
          &-item {
            text-align: left;
            font-weight: 400;
            color: var(--showcase-color-additional-dark);
            font-size: 15px;
            margin-bottom: var(--showcase-size-100);
            &-link {
              color: var(--showcase-color-additional-dark);
              text-decoration: none;
            }
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
      &-list {
        &-item {
          i {
            margin-top: 4px;
            font-size: 22px;
            &.extra-hand-shake {
              font-size: 20px;
            }
          }
          &-link {
            display: flex;
            align-items: flex-start;
            text-decoration: none;
            color: var(--showcase-color-additional-dark);
          }
          display: flex;
          align-items: flex-start;
          padding-bottom: var(--showcase-size-100);
          margin-bottom: var(--showcase-size-100);
          padding: var(--showcase-size-050);
          border-radius: 11px;
          position: relative;
          width: max-content;
          text-align: left;
          color: var(--showcase-color-additional-dark);
          &:after {
            content: "";
            display: block;
            height: 1px;
            width: 95%;
            background-color: rgba(0, 0, 0, 0.19);
            position: absolute;
            bottom: -8px;
          }
          &.recruteur {
            &:hover {
              background: rgba(107, 203, 216, 0.17);
            }
          }
          &:hover {
            background-color: rgba(255, 150, 170, 0.2);
            cursor: pointer;
            .submenu-lvl-1-list-item-lvl-2 {
              display: flex;
              width: 100%;
              position: relative;
            }
          }
          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: var(--showcase-size-050);
            &:after {
              display: none;
            }
          }
          &-text {
            margin-left: var(--showcase-size-100);
            font-size: 14px;
            min-width: 280px;
            &-title {
              font-weight: 500;
              display: flex;
              font-size: 16px;
              color: #000;
              line-height: 24px;
            }
            &-desc {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              display: block;
              margin-top: var(--showcase-size-100);
            }
          }
          &-lvl-2 {
            display: none;
          }
          &:last-child {
            i {
              margin-top: 2px;
            }
          }
        }
      }
      &-cta {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: var(--showcase-size-200);
      }
    }
  }
}
</style>
